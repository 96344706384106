import React, {FormEvent} from 'react';

import {Button, Form, Modal} from 'react-bootstrap';

import {Spinner} from 'shared/components/Spinner/Spinner';

import './style.scss';

interface IConfirmationModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    children: React.ReactNode;
    isSubmitting: boolean;
    onSubmit: () => void;
}

export const ConfirmationModal = ({show, onHide, children, onSubmit, isSubmitting}: IConfirmationModalProps) => {
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit();
    };

    const handleHide = () => {
        if (isSubmitting) {
            return;
        }
        onHide();
    };

    return (
        <Modal show={show} onHide={handleHide} dialogClassName="modal-sm" centered>
            <Modal.Header closeButton className="p-3 ">
                <Modal.Title className="headerProfile ">Confirmation</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button
                        className="ConfirmationModal__button"
                        variant="secondary"
                        type="button"
                        onClick={onHide}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="danger"
                        className="d-flex align-items-center"
                    >
                        {isSubmitting && <Spinner size="sm" className="mr-2" />}
                        Okay
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
